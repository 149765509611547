import { Filter } from "../types/filterType";

interface MentionListProps {
  items: Filter[];
  command: (item: { id: string; label: string }) => void;
}

class MentionList {
  private selectedIndex: number;
  private props: MentionListProps;
  private element: HTMLElement;

  constructor(props: MentionListProps, getIcon: (color: string) => {}) {
    this.selectedIndex = 0;
    this.props = props;
    this.element = document.createElement("div");
    this.element.className =
      "suggest-list flex flex-col items-start bg-neutral-700 rounded-md shadow-md";
    this.render(getIcon);
  }

  private selectItem(index: number) {
    const item = this.props.items[index];
    if (item) {
      this.props.command({ id: item.id, label: item.name });
    }
  }

  private upHandler = (getIcon: (color: string) => {}) => {
    this.selectedIndex =
      (this.selectedIndex + this.props.items.length - 1) %
      this.props.items.length;
    this.render(getIcon);
  };

  private downHandler = (getIcon: (color: string) => {}) => {
    this.selectedIndex = (this.selectedIndex + 1) % this.props.items.length;
    this.render(getIcon);
  };

  private enterHandler = () => {
    this.selectItem(this.selectedIndex);
  };

  public onKeyDown(
    event: KeyboardEvent,
    getIcon: (color: string) => {}
  ): boolean {
    if (event.key === "ArrowUp") {
      this.upHandler(getIcon);
      return true;
    }

    if (event.key === "ArrowDown") {
      this.downHandler(getIcon);
      return true;
    }

    if (event.key === "Enter") {
      this.enterHandler();
      return true;
    }

    return false;
  }

  public updateProps(props: MentionListProps, getIcon: (color: string) => {}) {
    this.props = props;
    this.selectedIndex = 0;
    this.render(getIcon);
  }

  private render(getIcon: (color: string) => {}) {
    this.element.innerHTML = "";
    if (this.props.items.length) {
      this.props.items.forEach((item: Filter, index) => {
        const button = document.createElement("button");
        button.innerHTML = `${getIcon(item.color)}<span>${item.name}</span>`;
        button.className = `py-1.5 px-2.5 w-full text-sm text-neutral-200 text-left hover:bg-neutral-600 flex ${
          index === this.selectedIndex ? "bg-neutral-600" : ""
        }`;
        //  button.textContent = item.name;
        button.onclick = () => this.selectItem(index);
        this.element.appendChild(button);
      });
    } else {
      const noResultDiv = document.createElement("div");
      noResultDiv.className =
        "py-1.5 px-2.5 w-full text-sm text-neutral-200 text-left";
      noResultDiv.textContent = "No result";
      this.element.appendChild(noResultDiv);
    }
  }

  public getElement() {
    return this.element;
  }

  public destroy() {
    this.element.remove();
  }
}

export default MentionList;

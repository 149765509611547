import { Filter, FilterResponse } from "../types/filterType";

export const prioritySuggestions: FilterResponse = {
  data: <Filter[]>[
    {
      id: "urgent",
      name: "1",
      color: "red-400",
    },
    {
      id: "high",
      name: "2",
      color: "yellow-400",
    },
    {
      id: "normal",
      name: "3",
      color: "blue-400",
    },
    {
      id: "low",
      name: "4",
      color: "neutral-400",
    },
  ],
};

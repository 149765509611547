import { Modal, ModalOptions } from "flowbite";
import TomSelect from "tom-select";
import { getFlagSVGbyColor } from "../icons/flag";
import { getHashtagSVGbyColor } from "../icons/hashtag";
import { getLabelSVGbyColor } from "../icons/label";
import { getLabels } from "./labels";
import { getProjects } from "./projects";
import {
  getTaskStartDate,
  initEditTaskAdvancedSelect,
  initEditTaskSelect,
  statusSpinner,
  tiptapNewTaskDescription,
  tiptapNewTaskName,
} from "./tasks";
// @ts-ignore
import { getAlpineDataByID } from "../store";
import { isParamSet, newDatePicker } from "../utils";
import { clearActionsSession, initActions } from "./actions";

// Modals
export let addNewTaskModal: Modal;
export let editTaskModal: Modal;
export let todayModal: Modal;
export let settingsModal: Modal;
export let focusModal: Modal;
// TomSelect
export let tomLabel: TomSelect;
export let tomProject: TomSelect;
export let tomPriority: TomSelect | null;
export let panelDatePicker: any;

let addNewTaskModalFlag = false;

// export const ModalsInitialized = () => {
//   window.addEventListener("load", function () {
//     InitFlowbiteInstancesAndEvents();
//   });
// };

export const modalOptions: ModalOptions = {
  placement: "center",
  backdrop: "dynamic",
  backdropClasses:
    "bg-neutral-900/50 dark:bg-neutral-700/80 fixed inset-0 z-40",
  closable: true,
};

export const initFlowbiteModals = () => {
  if (!tomPriority) {
    tomPriority = initEditTaskSelect("#priority-select-new", getFlagSVGbyColor);
    if (tomPriority) tomPriority.setValue("normal");
  }

  todayModal = new Modal(
    document.getElementById("today-modal"),
    {},
    {
      id: "todayModal",
      override: true,
    }
  );

  focusModal = new Modal(
    document.getElementById("focus-modal"),
    {},
    {
      id: "focusModal",
      override: true,
    }
  );

  addNewTaskModal = new Modal(
    document.getElementById("new-task-modal"),
    modalOptions,
    {
      id: "new-task-modal",
      override: true,
    }
  );
  editTaskModal = new Modal(
    document.getElementById("edit-task-modal"),
    modalOptions,
    {
      id: "edit-task-modal",
      override: true,
    }
  );

  settingsModal = new Modal(
    document.getElementById("settings-modal"),
    modalOptions,
    {
      id: "settings-modal",
      override: true,
    }
  );

  focusModal.updateOnHide(() => {
    (<HTMLElement>(
      document.querySelector("#focus-modal .modal-body")
    )).innerHTML = "";
    Alpine.store("tasks").focusModalFlag = false;
    window.history.pushState("", "", "/");
  });

  // addNewTaskModal = FlowbiteInstances.getInstance("Modal", "new-task-modal");
  // editTaskModal = FlowbiteInstances.getInstance("Modal", "edit-task-modal");
  addNewTaskModal.updateOnHide(() => {
    (<HTMLElement>document.querySelector(".actions-container")).innerHTML = "";
    // reset Due Date picker

    // const dueDateInput = document.getElementById(
    //   "due-date"
    // ) as HTMLInputElement | null;

    // if (dueDateInput) {
    //   dueDateInput.value = "";
    // }

    //tomPriority.destroy();
    //tomPriority.setValue("normal");

    document.querySelectorAll(".tom-input").forEach((el) => {
      el.classList.add("hidden");
    });
    addNewTaskModalFlag = false;
  });

  addNewTaskModal.updateOnShow(async () => {
    addNewTaskModalFlag = true;

    (<HTMLButtonElement>document.getElementById("add-task-btn")).disabled =
      true;
    // reset Alpine data for new task modal
    const newTaskModalData = getAlpineDataByID("new-task-modal");
    if (newTaskModalData.date) {
      newTaskModalData.date = "";
    }
    newTaskModalData.inbox = false;

    tiptapNewTaskName.commands.clearContent();
    tiptapNewTaskDescription.commands.clearContent();

    const eventContainer = document.getElementById("event-container-new");
    if (eventContainer) eventContainer.innerHTML = "";

    if (tomLabel) tomLabel.destroy();
    if (tomProject) tomProject.destroy();
    if (tomPriority) tomPriority.setValue("normal");
    tiptapNewTaskName.commands.focus();

    let datepickerEl = document.getElementById("panel-date-new");
    if (!panelDatePicker) {
      panelDatePicker = newDatePicker(datepickerEl);
    }

    // promise all
    const [_, startDate] = await Promise.all([
      clearActionsSession(),
      getTaskStartDate(),
    ]);
    initActions("");

    if (startDate) {
      panelDatePicker.setDate(new Date(startDate));
    } else {
      (<HTMLInputElement>datepickerEl).value = "";
      panelDatePicker.destroy();
      panelDatePicker = newDatePicker(datepickerEl);
    }

    const [labels, projects] = await Promise.all([
      getLabels(Alpine.store("viewType")),
      getProjects(Alpine.store("viewType")),
    ]);

    tomLabel = initEditTaskAdvancedSelect(
      "#label-select-new",
      labels.data,
      "open-label-select-new",
      getLabelSVGbyColor
    );

    tomProject = initEditTaskAdvancedSelect(
      "#project-select-new",
      projects.data,
      "open-project-select-new",
      getHashtagSVGbyColor
    );

    setTimeout(() => {
      document.querySelectorAll(".ts-wrapper").forEach((el) => {
        el.classList.remove("hidden");
      });
    }, 500);
  });

  editTaskModal.updateOnHide(() => {
    if (statusSpinner) statusSpinner.classList.add("hidden");
    // clear edit modal body
    document.querySelectorAll("#edit-task-modal .modal-body").forEach((el) => {
      el.innerHTML = "";
    });

    window.history.pushState("", "", "/");
    //getAlpineDataByID("edit-task-modal").fullscreen = false;
  });

  editTaskModal.updateOnShow(() => {
    //
  });

  settingsModal.updateOnHide(() => {
    const settingModal = document.querySelector("#settings-modal");
    if (settingModal) settingModal.innerHTML = "";
  });
};

export const setupModalEventListeners = async () => {
  console.log("Setting up modal event listeners");

  // Add new task modal
  document.querySelectorAll(".open-new-task-modal").forEach((el) => {
    el.addEventListener("click", () => {
      openNewTaskModal();
    });
  });

  document.querySelectorAll(".open-today-modal").forEach((el) => {
    el.addEventListener("click", () => {
      todayModal.show();
    });
  });

  document.querySelectorAll(".open-focus-modal").forEach((el) => {
    el.addEventListener("click", () => {
      focusModal.show();
      console.log("Trigger fetch of data!");
    });
  });

  document.querySelectorAll(".switch-to-today").forEach((el) => {
    el.addEventListener("click", () => {
      focusModal.hide();
      editTaskModal.hide();
      todayModal.show();
    });
  });

  document.querySelectorAll(".close-today-modal").forEach((el) => {
    el.addEventListener("click", () => {
      todayModal.hide();
    });
  });

  document.querySelectorAll(".close-focus-modal").forEach((el) => {
    el.addEventListener("click", () => {
      focusModal.hide();
    });
  });

  document.querySelectorAll(".settings-menu-item").forEach((el) => {
    el.addEventListener("click", () => {
      settingsModal.show();
    });
  });

  const rightSidebarTabs = FlowbiteInstances.getInstance(
    "Tabs",
    "main-sidebar"
  );

  rightSidebarTabs.updateOnShow((tab: any) => {
    if (tab.getActiveTab().id === "#main-sidebar-clickup") {
      //initClickupEvent();
    }
  });

  if (isParamSet("clickup")) {
    document.getElementById("main-sidebar-clickup-tab")?.click();
  }
  //tabs.getTab("#main-sidebar-clickup");

  document
    .getElementById("manage-labels-button")
    ?.addEventListener("click", () => {
      settingsModal.show();
    });

  document
    .getElementById("manage-projects-button")
    ?.addEventListener("click", () => {
      settingsModal.show();
    });
};

export const openNewTaskModal = () => {
  if (!addNewTaskModalFlag) addNewTaskModal.show();
};

export const initClickupEvent = () => {
  document
    .getElementById("main-sidebar-clickup")
    ?.dispatchEvent(new Event("clickup"));
};

export function showFocusModal() {
  focusModal.show();
}

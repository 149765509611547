import Alpine from "alpinejs";
import { formatDate } from "date-fns";
import { initOtpForm } from "./auth";
import {
  deleteAction,
  initActionPriorityDropdown,
  initEditActionName,
  updateAction,
} from "./components/actions";
import {
  dispatchTaskDoneEvent,
  displatchTaskDateChangedEvent,
} from "./components/calendar";
import {
  afterClickupLoaded,
  afterClickupTasksLoaded,
} from "./components/clickup";
import { openNewTaskModal, showFocusModal } from "./components/flowbite";
import {
  initFilterDropdown,
  initializeCalendar,
  initTipTapEventDescription,
  initTipTapEventName,
} from "./components/fullcalendar";
import { afterInboxLoaded } from "./components/inbox";
import {
  afterSettingsLoad,
  hideColorFilterDropdown,
  initEditFilterName,
  initFilterColorDropdown,
} from "./components/settings";
import {
  hidePopup,
  initLabelDropdown,
  initLabelOptionsDropdown,
  initProjectDropdown,
  initProjectOptionsDropdown,
} from "./components/sidebar";
import {
  addNewTask,
  addToTasks,
  afterDeleteTask,
  exportTaskToMD,
  initTipTapEditTaskName,
  initTipTapNewTaskName,
  initTipTapTaskDescription,
  showTaskContextMenu,
  updateTask,
  updateTipTapNewTaskName,
} from "./components/tasks";

export const appStore = () => {
  Alpine.store("viewType", "work");
  Alpine.store("currentDate", formatDate(new Date(), "yyyy-MM-dd"));
  Alpine.store("label", "");
  Alpine.store("project", "");
  Alpine.store("googleCalendars", []);
  Alpine.store("collapsed", false);

  const priorities = (<HTMLFormElement>document.querySelector("#priorities"))
    ?.textContent;
  if (priorities) {
    Alpine.store("priorities", JSON.parse(priorities));
  }
  Alpine.store("tasks", {
    showTaskContextMenu,
    addNewTask,
    initTipTapNewTaskName,
    updateTipTapNewTaskName,
    dispatchTaskDoneEvent,
    displatchTaskDateChangedEvent,
    initTipTapEditTaskName,
    afterDeleteTask,
    exportTaskToMD,
    focusModalFlag: false,
    initTipTapTaskDescription,
    updateTask,
    updateAction,
    deleteAction,
    initEditActionName,
    initActionPriorityDropdown,
    initOtpForm,
    openNewTaskModal,
    showFocusModal,
    afterInboxLoaded,
  });

  Alpine.store("calendar", {
    addToTasks,
    initTipTapEventName,
    initTipTapEventDescription,
    initFilterDropdown,
    initializeCalendar,
  });

  Alpine.store("settings", {
    afterSettingsLoad,
    initEditFilterName,
    initFilterColorDropdown,
    hideColorFilterDropdown,
  });

  Alpine.store("clickup", {
    afterClickupLoaded,
    afterClickupTasksLoaded,
  });

  Alpine.store("sidebar", {
    expanded: true,
    toggle() {
      // @ts-ignore
      this.expanded = !this.expanded;
    },
    initLabelOptionsDropdown,
    initLabelDropdown,
    initProjectOptionsDropdown,
    initProjectDropdown,
    hidePopup,
  });
};

export const getAlpineDataByID = (id: string): any => {
  return Alpine.$data(<HTMLElement>document.getElementById(id)) as any;
};

export const getAlpineDataBySelector = (selector: string): any => {
  return Alpine.$data(<HTMLElement>document.querySelector(selector)) as any;
};

export const getAlpineDataByElement = (el: HTMLElement | null): any => {
  if (!el) {
    return null;
  }
  return Alpine.$data(el) as any;
};

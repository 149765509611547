import Sortable, { SortableEvent } from "sortablejs";
import { sortTasks } from "./tasks";

export const afterInboxLoaded = () => {
  initInboxSortable();
};

export const initInboxSortable = () => {
  const inboxesTasks = document.querySelectorAll(".inbox-tasks");
  inboxesTasks.forEach((inboxTasks) => {
    Sortable.create(<HTMLElement>inboxTasks, {
      group: {
        name: "tasks",
        pull: true,
        put: false,
      },
      sort: false,
      animation: 100,
      delay: 25,
      delayOnTouchOnly: false,
      forceFallback: true,
      dragClass: "drag-card",
      ghostClass: "ghost-card",
      easing: "cubic-bezier(0, 0.55, 0.45, 1)",
      onEnd: (evt: SortableEvent) => {
        sortTasks(evt);
      },
    });
  });
};

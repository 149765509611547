import htmx from "htmx.org";
import tippy, { Instance } from "tippy.js";

let popup: Instance[];

export function initLabelOptionsDropdown() {
  const SCRIPT_ID = "#label-options-dropdown";
  const POPOVER_ID = "#label-options-dropdown-item";

  const labels = document.querySelectorAll(".label-popover");

  if (!labels.length) {
    // I dont think we need an error here
    // because not everyone will have labels
    // console.error("No labels found");
    return;
  }

  const dropdownPrioritDiv = document.createElement("div");
  dropdownPrioritDiv.innerHTML = JSON.parse(
    <string>(<HTMLFormElement>document.querySelector(SCRIPT_ID)).textContent
  );

  const content = dropdownPrioritDiv.querySelector(POPOVER_ID);

  if (!content) {
    console.error("No content found");
    return;
  }

  popup = tippy("body", {
    appendTo: () => document.body,
    content: content,
    showOnCreate: false,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
  });

  for (const label of labels) {
    label.addEventListener("click", (event) => {
      event.preventDefault();
      const clickedElementRect = label.getBoundingClientRect();

      popup[0].setProps({
        getReferenceClientRect: () => clickedElementRect,
      });

      popup[0].show();
      htmx.process(document.body);
    });
  }
}

export function initLabelDropdown() {
  const SCRIPT_ID = "#label-dropdown";
  const POPOVER_ID = "#label-dropdown-item";

  const collapsedButton = document.querySelector("#label-collapsed-button");

  const dropdownPrioritDiv = document.createElement("div");
  dropdownPrioritDiv.innerHTML = JSON.parse(
    <string>(<HTMLFormElement>document.querySelector(SCRIPT_ID)).textContent
  );

  if (!collapsedButton) {
    console.error("No collapsed button found");
    return;
  }

  const content = dropdownPrioritDiv.querySelector(POPOVER_ID);

  if (!content) {
    console.error("No content found");
    return;
  }

  tippy(collapsedButton, {
    content: content,
    showOnCreate: false,
    interactive: true,
    placement: "right-start",
  });

  // htmx.process(document.body);
}

export function initProjectOptionsDropdown() {
  const SCRIPT_ID = "#project-options-dropdown";
  const POPOVER_ID = "#project-options-dropdown-item";

  const labels = document.querySelectorAll(".project-popover");

  if (!labels.length) {
    //console.error("No projects found");
    return;
  }

  const dropdownPrioritDiv = document.createElement("div");
  dropdownPrioritDiv.innerHTML = JSON.parse(
    <string>(<HTMLFormElement>document.querySelector(SCRIPT_ID)).textContent
  );

  const content = dropdownPrioritDiv.querySelector(POPOVER_ID);

  if (!content) {
    console.error("No content found");
    return;
  }

  popup = tippy("body", {
    appendTo: () => document.body,
    content: content,
    showOnCreate: false,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
  });

  for (const label of labels) {
    label.addEventListener("click", (event) => {
      event.preventDefault();
      const clickedElementRect = label.getBoundingClientRect();

      popup[0].setProps({
        getReferenceClientRect: () => clickedElementRect,
      });

      popup[0].show();
      htmx.process(document.body);
    });
  }
}

export function initProjectDropdown() {
  const SCRIPT_ID = "#project-dropdown";
  const POPOVER_ID = "#project-dropdown-item";

  const collapsedButton = document.querySelector("#project-collapsed-button");

  const dropdownPrioritDiv = document.createElement("div");
  dropdownPrioritDiv.innerHTML = JSON.parse(
    <string>(<HTMLFormElement>document.querySelector(SCRIPT_ID)).textContent
  );

  if (!collapsedButton) {
    console.error("No collapsed button found");
    return;
  }

  const content = dropdownPrioritDiv.querySelector(POPOVER_ID);

  if (!content) {
    console.error("No content found");
    return;
  }

  tippy(collapsedButton, {
    content: content,
    showOnCreate: false,
    interactive: true,
    placement: "right-start",
  });

  // htmx.process(document.body);
}

export function hidePopup() {
  popup[0].hide();
}

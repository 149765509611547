import { getFlagSVGbyColor } from "../icons/flag";
import { getHashtagSVGbyColor } from "../icons/hashtag";
import { getLabelSVGbyColor } from "../icons/label";
import { initActions } from "./actions";
import { initEditTaskAdvancedSelect, initEditTaskSelect } from "./tasks";

async function getFocusModal(taskID: string): Promise<any> {
  const resp = await fetch(
    "/api/users/tasks/focus-mode?" +
      new URLSearchParams({
        id: taskID,
        view_type: String(Alpine.store("viewType")),
      }),
    {
      method: "GET",
    }
  );

  return await resp.json();
}

export async function onFocusModal(taskID: string) {
  if (Alpine.store("tasks").focusModalFlag) {
    return;
  }
  Alpine.store("tasks").focusModalFlag = true;
  const taskEditModal = await getFocusModal(taskID);
  Alpine.store("tasks").focusModalFlag = false;
  Alpine.store("tasks").showFocusModal();

  (<HTMLElement>document.querySelector("#focus-modal .modal-body")).innerHTML =
    taskEditModal.focusMode;

  initActions(taskID);

  initEditTaskAdvancedSelect(
    "#label-select-focus",
    taskEditModal.labels,
    "open-label-select-focus",
    getLabelSVGbyColor,
    "label_id",
    taskEditModal.task.label_id,
    taskID
  );
  initEditTaskAdvancedSelect(
    "#project-select-focus",
    taskEditModal.projects,
    "open-project-select-focus",
    getHashtagSVGbyColor,
    "project_id",
    taskEditModal.task.project_id,
    taskID
  );

  initEditTaskSelect(
    "#priority-select-focus",
    getFlagSVGbyColor,
    "priority",
    taskEditModal.task.priority,
    taskID
  );
}

import Sortable from "sortablejs";

import { SortableEvent } from "sortablejs";

import { sortTasks } from "./tasks";
import { setupModalEventListeners } from "./flowbite";

export const initWeeklyView = (toDate: string, direction: string) => {
  initSortable();
  scrollToWeeklyDay(toDate, direction);
  setupModalEventListeners();
};

export const initSortable = () => {
  const taskColumns = document.querySelectorAll(".task-column");
  taskColumns.forEach((taskColumn) => {
    Sortable.create(<HTMLElement>taskColumn, {
      group: {
        name: "tasks",
      },
      animation: 100,
      delay: 25,
      delayOnTouchOnly: false,
      forceFallback: true,
      dragClass: "drag-card",
      ghostClass: "ghost-card",
      easing: "cubic-bezier(0, 0.55, 0.45, 1)",
      onChoose(evt: SortableEvent) {
        evt.item.classList.remove("task-card-hover");
      },
      onEnd: (evt: SortableEvent) => {
        evt.item.classList.add("task-card-hover");
        sortTasks(evt);
      },
    });
  });
};

const scrollToWeeklyDay = (toDate: string, direction: string) => {
  const container = <HTMLElement>document.querySelector("main");

  const targetElement = <HTMLElement>(
    document.getElementById("task-column-" + toDate)
  );

  let scrollAmount: number;
  const padding = 16;
  if (direction === "before" || direction === "") {
    scrollAmount = targetElement.offsetLeft - container?.offsetLeft - padding;
  } else {
    const containerWidth = container.clientWidth;
    const targetRightEdge = targetElement.offsetLeft + 2 * padding; //+ targetElement.clientWidth;
    scrollAmount = targetRightEdge - containerWidth;
  }

  container.scrollLeft = scrollAmount;
};

export const calcNumberOfTasks = (date: string) => {
  const taskColumns = document.querySelectorAll(
    `#task-column-${date} .task-column > div`
  );

  const taskColumnElm = <HTMLElement>(
    document.querySelector("#task-column-" + date + " .daily-task-count")
  );
  if (!taskColumnElm) {
    return;
  }

  taskColumnElm.innerText = taskColumns.length.toString();
};

const viewTypeEventName = "viewType-changed";

export const displatchViewTypeEvent = (id?: string) => {
  if (id) {
    document.getElementById(id)?.dispatchEvent(new Event(viewTypeEventName));
    return;
  }
  document
    .getElementById("weekly-task-view")
    ?.dispatchEvent(new Event(viewTypeEventName));

  document
    .getElementById("dropdown-labels")
    ?.dispatchEvent(new Event(viewTypeEventName));

  document
    .getElementById("dropdown-projects")
    ?.dispatchEvent(new Event(viewTypeEventName));

  document
    .getElementById("main-sidebar-inbox")
    ?.dispatchEvent(new Event(viewTypeEventName));
};

export const dispatchTaskDoneEvent = () => {
  document
    .getElementById("weekly-task-view")
    ?.dispatchEvent(new Event("taskDone-updated"));
};

export const displatchTaskDateChangedEvent = (
  taskDateUpdated: boolean,
  newInbox: boolean = true
) => {
  if (taskDateUpdated) {
    document
      .querySelector("#weekly-task-view")
      ?.dispatchEvent(new Event("taskDate-updated"));
  }

  if (newInbox) {
    document
      .querySelector("#main-sidebar-inbox")
      ?.dispatchEvent(new Event("new-inbox"));
  }
};

// const startOfTheWeek = formatDate(
//   startOfWeek(new Date(), { weekStartsOn: 1 }),
//   "yyyy-MM-dd"
// );

// Alpine.start();

// htmx.onLoad(function (target) {});

// htmx.on("htmx:beforeHistorySave", function () {});

// const modal = new Modal(
//   <HTMLElement>document.getElementById("openModal"),
//   <ModalOptions>{
//     onShow: () => {
//       console.log("modal show");
//     },
//     onHide: () => {
//       console.log("modal hide");
//     },
//     closable: true,
//     placement: "center",
//   }
// );
// modal.hide();

// let data = Alpine.reactive({ count: 1 });

// Alpine.effect(() => {
//   (document.querySelector("span") as HTMLSpanElement).textContent =
//     data.count.toString();
// });

// const button = document.querySelector("button") as HTMLButtonElement;

// button.addEventListener("click", () => {
//   data.count = data.count + 1;
// });

// htmx.onLoad(function (content) {
//   var sortables = content.querySelectorAll(".sortable");
//   for (var i = 0; i < sortables.length; i++) {
//     var sortable = <HTMLElement>sortables[i];
//     var sortableInstance = new Sortable(sortable, {
//       animation: 150,
//       ghostClass: "blue-background-class",

//       // Make the `.htmx-indicator` unsortable
//       filter: ".htmx-indicator",
//       onMove: function (evt) {
//         return evt.related.className.indexOf("htmx-indicator") === -1;
//       },

//       // Disable sorting on the `end` event
//       onEnd: function () {
//         //this.option("disabled", true);
//         sortableInstance.option("disabled", true);
//       },
//     });

//     // Re-enable sorting on the `htmx:afterSwap` event
//     sortable.addEventListener("htmx:afterSwap", function () {
//       sortableInstance.option("disabled", false);
//     });
//   }
// });

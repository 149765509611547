// main.ts
import "tom-select/dist/css/tom-select.css";
import "./fonts";
import "./style.css";

import "vite/modulepreload-polyfill";

import "./hotkeys";

import { initFlowbite } from "flowbite";
import htmx from "htmx.org";
//import { loadHTMXMultiSwap } from "./htmx/multiswap";

import {
  calcNumberOfTasks,
  displatchViewTypeEvent,
  initSortable,
  initWeeklyView,
} from "./components/calendar";

import {
  onTaskEditModal,
  openEditTaskModal,
  preloadAudio,
  TaskColumnPrefix,
  updateTiptapPanelDate,
} from "./components/tasks";

import Alpine from "alpinejs";
import { initClickupEvent, initFlowbiteModals } from "./components/flowbite";
import "./components/toasts";
import { initHotkeys } from "./hotkeys";
import { appStore } from "./store";

import { Editor } from "@tiptap/core";
import "js-circle-progress";
import { onFocusModal } from "./components/focus";
import { RightSidebar } from "./components/right-sidebar";
import {
  tiptapLabelPersonalInput,
  tiptapLabelWorkInput,
} from "./components/settings";
import { initToday } from "./components/today";
import "./components/search";

window.Alpine = Alpine;
window.htmx = htmx;
//loadHTMXMultiSwap();

document.addEventListener("alpine:init", () => {
  appStore();
});

// Init Flowbite

initFlowbiteModals();
initFlowbite();

// load audio assets
preloadAudio();

// htmx.onLoad(function (element: Element) {
//   //console.log(element);
// });

interface AppInterface {
  InitWeeklyView: (toDate: string, direction: string) => void;
  OnTaskEditModal: any;
  OnFocusModal: any;
  DisplatchViewTypeEvent: any;
  CalcNumberOfTasks: any;
  TiptapLabelWorkInput: Editor;
  TiptapLabelPersonalInput: Editor;
  TiptapProjectWorkInput: Editor;
  TiptapProjectPersonalInput: Editor;
}

htmx.on("htmx:after-request", (evt: Event) => {
  const customEvent = <CustomEvent>evt;
  const targetId = customEvent.detail.requestConfig.headers["HX-Trigger"];

  // If inital weekly view load
  if (targetId === "weekly-task-view") {
    initWeeklyView(<string>Alpine.store("currentDate"), "");
  }

  // If horizonatal scroll reaches certain trigger point
  if (targetId?.startsWith(TaskColumnPrefix)) {
    const taskColumnDate = targetId.substring(TaskColumnPrefix.length);

    initWeeklyView(
      taskColumnDate,
      customEvent.detail.requestConfig.parameters?.direction
    );
  }
  if (targetId === "daily-task-view") {
    initSortable();
  }
});

const rightSidebarMain = new RightSidebar({
  navId: "main-sidebar",
  contentId: "main-sidebar-content",
});

new RightSidebar({
  navId: "today-sidebar",
  contentId: "today-sidebar-content",
  defaultTabId: "today-sidebar-google-tab",
});

// Setup event listeners.
const topSearchBarInput = <HTMLInputElement>(
  document.getElementById("topbar-search")
);

window.addEventListener("load", () => {
  initHotkeys(rightSidebarMain, topSearchBarInput);
  openEditTaskModal();
  updateTiptapPanelDate();
  initClickupEvent();
});

document.addEventListener("click", (e) => {
  if (e.target !== topSearchBarInput) {
    if (topSearchBarInput.value) {
      topSearchBarInput.value = "";
      htmx.trigger("#topbar-search", "search");
    }
  }
});

initToday();

// Alpine.store("tabs", {
//   current: "first",

//   items: ["first", "second", "third"],
// });

// Set global variable

export const App: AppInterface = {
  InitWeeklyView: initWeeklyView,
  OnTaskEditModal: onTaskEditModal,
  OnFocusModal: onFocusModal,
  DisplatchViewTypeEvent: displatchViewTypeEvent,
  CalcNumberOfTasks: calcNumberOfTasks,
  TiptapLabelWorkInput: tiptapLabelWorkInput,
  TiptapLabelPersonalInput: tiptapLabelPersonalInput,
  TiptapProjectWorkInput: tiptapLabelWorkInput,
  TiptapProjectPersonalInput: tiptapLabelPersonalInput,
};

window.App = App;

Alpine.start();

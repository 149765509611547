import tippy, { GetReferenceClientRect, Instance } from "tippy.js";
import MentionList from "./mentionList";
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import { PluginKey } from "@tiptap/pm/state";
import Mention from "@tiptap/extension-mention";
import { FilterResponse } from "../types/filterType";
import { tomLabel, tomPriority, tomProject } from "./flowbite";
import { getLabels } from "./labels";
import { getProjects } from "./projects";

export default (
  char: string,
  identifier: string,
  filters: FilterResponse | null,
  getIcon: (color: string) => {},
  filterName?: string
) => {
  return Mention.extend({
    name: `mention${identifier}`,
  }).configure({
    HTMLAttributes: {
      class: `mention-${identifier}`,
    },

    renderHTML({ options, node }) {
      setTimeout(() => {
        if (options.HTMLAttributes["data-type"] == "mentionat") {
          if (options.HTMLAttributes["data-id"] != tomLabel.getValue()) {
            tomLabel.setValue(options.HTMLAttributes["data-id"]);
          }
        }

        if (options.HTMLAttributes["data-type"] == "mentionhash") {
          if (options.HTMLAttributes["data-id"] != tomProject.getValue()) {
            tomProject.setValue(options.HTMLAttributes["data-id"]);
          }
        }

        if (options.HTMLAttributes["data-type"] == "mentionp") {
          if (!tomPriority) return;
          if (options.HTMLAttributes["data-id"] != tomPriority.getValue()) {
            tomPriority.setValue(options.HTMLAttributes["data-id"]);
          }
        }
      }, 100);

      return [
        "span",
        {
          "data-type": options.HTMLAttributes["data-type"],
          "data-id": options.HTMLAttributes["data-id"],
          class: `rounded p-1 bg-accent-purple-1 ${options.HTMLAttributes["class"]} `,
        },
        `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
      ];
    },
    suggestion: {
      pluginKey: new PluginKey(`mention-${identifier}`),
      char: char,
      items: async ({ query }: { query: string }) => {
        if (filterName === "labels") {
          filters = await getLabels(Alpine.store("viewType"));
        } else if (filterName === "projects") {
          filters = await getProjects(Alpine.store("viewType"));
        }

        if (!filters) return [];
        return filters.data
          .filter((item: any) =>
            item.name.toLowerCase().includes(query.toLowerCase())
          )
          .slice(0, 5);
      },
      allowSpaces: true,
      render: () => {
        let component: MentionList;
        let popup: Instance[];
        return {
          onStart: (props: SuggestionProps) => {
            if (props.text === "@") {
              const labelElm = document.querySelectorAll(".mention-at");
              if (labelElm.length > 0) return;
            }
            if (props.text === "#") {
              const labelElm = document.querySelectorAll(".mention-hash");
              if (labelElm.length > 0) return;
            }
            if (props.text === "p") {
              const labelElm = document.querySelectorAll(".mention-p");
              if (labelElm.length > 0) return;
            }
            component = new MentionList(props, getIcon);

            if (!props.clientRect) {
              return;
            }

            popup = tippy("body", {
              getReferenceClientRect: <
                GetReferenceClientRect | null | undefined
              >props.clientRect,
              appendTo: () => document.body,
              content: component.getElement(),
              showOnCreate: true,
              interactive: true,
              trigger: "manual",
              placement: "bottom-start",
            });
            // alert("start");
          },

          onUpdate(props: SuggestionProps) {
            if (component) {
              component.updateProps(props, getIcon);
            }

            if (popup && props.clientRect) {
              popup[0].setProps({
                getReferenceClientRect: <
                  GetReferenceClientRect | null | undefined
                >props.clientRect,
              });
            }
          },

          onKeyDown(props: SuggestionKeyDownProps) {
            if (props.event.key === "Escape") {
              popup[0].hide();

              return true;
            }

            return component
              ? component.onKeyDown(props.event, getIcon)
              : false;
            /// alert("keydown");
          },

          onExit() {
            if (popup) {
              popup[0].destroy();
            }
            if (component) {
              component.destroy();
            }
            // alert("exit");
          },
        };
      },
    },
  });
};

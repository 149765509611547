import { Calendar, EventContentArg } from "@fullcalendar/core";

import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Editor, Node } from "@tiptap/core";
import Heading from "@tiptap/extension-heading";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import { formatDate } from "date-fns";
import { Dropdown, DropdownOptions, InstanceOptions, Popover } from "flowbite";
import { handleHttpError, processHTMX } from "../utils";
import { openNewTaskModal } from "./flowbite";

interface CalendarEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  attendees: { email: string; displayName: string; id: string }[];
  className: string;
}

const formatEventTime = (date: Date | null): string => {
  return (
    date?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) ?? ""
  );
};

function renderEventContent(arg: EventContentArg, calendarId: string) {
  const { id, title, start, end, startStr, endStr } = arg.event;

  return {
    html: `
      <div 
       hx-get="/api/users/events/google/${id}"
       @click="$store.tasks.openNewTaskModal(); $dispatch('set-googlecalendar', {id: 'primary', eventID: '${id}'});"
       hx-swap="outerHTML"
       hx-target="#event-container-new"
       @htmx:after-request="$store.tasks.updateTipTapNewTaskName('${title}');"
       hx-vals='{"calendar_id": "primary"}' data-popover-target="popover-${calendarId}-${id}" data-popover-trigger="hover" class="open-edit-event-modal h-full p-2">
        <p class="font-semibold">${title}</p>
        <p>
          <time datetime="${startStr}">${formatEventTime(start)}</time> - 
          <time datetime="${endStr}">${formatEventTime(end)}</time>
        </p>
      </div>
    `,
  };
}

export async function initializeCalendar(
  calendarId: string,
  portalId: string
): Promise<void> {
  const calendarElement = document.getElementById(calendarId);

  if (!calendarElement) {
    console.error(`Calendar element not found`);
    return;
  }

  const googleCalendarIds = Alpine.store("googleCalendars");
  const googleEvents = await getGoogleCalendarEvents(googleCalendarIds);

  const calendar = new Calendar(calendarElement, {
    plugins: [timeGridPlugin, interactionPlugin],
    initialView: "timeGridDay",
    nowIndicator: true,
    headerToolbar: false,
    allDaySlot: false,
    contentHeight: "auto",
    slotMinTime: "00:00:00",
    slotMaxTime: "24:00:00",
    slotLabelFormat: { hour: "numeric", minute: "2-digit" },
    slotLabelClassNames: "text-xs leading-5 text-neutral-400",
    eventClassNames:
      "group rounded-lg text-xs leading-5 !border-none !shadow-none !outline-none",
    editable: false,
    dayHeaderClassNames: "hidden",
    eventContent: (arg) => renderEventContent(arg, calendarId),
    events: googleEvents,
    slotDuration: "00:30:00",
  });

  calendar.render();

  processHTMX();

  // Create popover elements
  googleEvents?.forEach((event) => {
    const popoverElement = document.createElement("div");

    let attendees = "";
    event.attendees?.forEach((attendee) => {
      attendees += `<div class="flex items-center gap-2">
        <div class="size-5 rounded-full bg-neutral-700"></div>
        <p class="text-sm">${attendee.displayName ? attendee.displayName : attendee.email}</p>
      </div>`;
    });

    popoverElement.innerHTML = `
      <div data-popover id="popover-${calendarId}-${event.id}" role="tooltip" class="invisible absolute z-50 inline-block w-64 rounded-lg border border-neutral-700 bg-neutral-900 p-4 text-sm opacity-0 shadow-sm transition-opacity duration-300">
        <div class="flex justify-end gap-2">
          <button type="button" x-on:click="$store.calendar.addToTasks('${event.title}', 'primary', '${event.id}')" class="h-8 rounded-lg bg-neutral-800 px-3 text-xs font-medium text-white hover:bg-neutral-900 focus:outline-none dark:border-neutral-700 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:focus:ring-neutral-700">
            Add to tasks
          </button>

          <button type="button" class="h-8 rounded-lg bg-neutral-800 px-3 text-sm font-medium text-white hover:bg-neutral-900 focus:outline-none dark:border-neutral-700 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:focus:ring-neutral-700">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>
          </button>
        </div>
        <div class="my-4 h-px w-full bg-neutral-700"></div>
        <div class="flex flex-col gap-2">
          <p class="mb-2 text-lg">${event.title}</p>
          
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-neutral-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <p class="text-sm">${event.start ? formatDate(new Date(event.start), "hh:mm") : ""} -  ${event.end ? formatDate(new Date(event.end), "hh:mm a") : ""}</p>
          </div>
          ${attendees}
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-purple-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5" />
            </svg>
            <p class="text-sm">meetings</p>
          </div>
        </div>
        <div data-popper-arrow></div>
      </div>
    `;

    document
      .getElementById(portalId)
      ?.appendChild(popoverElement.firstElementChild!);
  });

  const popoverElements = document.querySelectorAll("[data-popover-target]");

  popoverElements.forEach((el) => {
    const targetId = el.getAttribute("data-popover-target");
    const target = document.getElementById(targetId!);

    if (target) {
      new Popover(target, el as HTMLElement, {
        placement: "left",
        triggerType: "hover",
      });
    }
  });

  document.querySelectorAll(".open-edit-event-modal").forEach((el) => {
    el.addEventListener("click", () => {
      openNewTaskModal();
    });
  });

  // initEditTaskSelect(
  //   "#priority-select-event",
  //   getFlagSVGbyColor,
  //   "priority",
  //   "normal"
  // );
}

export function initTipTapEventName(content: string) {
  const tipTapEditorElm = document.querySelector(".tiptap-edit-event-name");
  if (!tipTapEditorElm) {
    throw new Error("Error initializing tiptap editor");
  }

  new Editor({
    element: tipTapEditorElm,
    extensions: [
      Node.create({
        name: "doc",
        topNode: true,
        content: "text*",
      }),
      Node.create({
        name: "text",
        group: "inline",
      }),
      Heading,
      Placeholder.configure(),
    ],
    editorProps: {
      attributes: {
        class: "prose dark:prose-invert prose-2xl mb-4 focus:outline-none",
        "data-placeholder-text": "Task name...",
      },
    },
    content: content,
  });
}

export function initTipTapEventDescription(
  content: string = "",
  selector: string
) {
  const tipTapEditorElm = document.querySelector(selector);
  if (!tipTapEditorElm) {
    throw new Error(`No element with class '${selector}' found`);
  }

  new Editor({
    element: tipTapEditorElm,
    extensions: [
      StarterKit,
      TaskList.configure({
        HTMLAttributes: {
          class: "taskList list-none p-0",
        },
      }),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class:
            "taskItem flex gap-2 m-0 [&:not(:has(ul))]:items-center [&_ul]:m-0",
        },
      }),
      Placeholder.configure({
        placeholder: "Description...",
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-li:m-0",
      },
    },
    content: content,
  });
}

/* i hae this attendees
: 
[{email: "edward.marinescu@gmail.com"}, {email: "edi_smecheru@yahoo.com"}]
0
: 
{email: "edward.marinescu@gmail.com"}
email
: 
"edward.marinescu@gmail.com"
*/

async function getGoogleCalendarEvents(
  calendarIds: string[] = ["primary"]
): Promise<CalendarEvent[]> {
  const encodedCalendarIds = calendarIds.map(encodeURIComponent).join(",");

  const resp = await fetch(
    `/api/users/events/google?date=${formatDate(new Date(), "yyyy-MM-dd")}&calendar_id=${encodedCalendarIds}`
  );

  const respJson = await resp.json();

  handleHttpError(resp, respJson);

  return <CalendarEvent[]>respJson.data;
}

// @ts-ignore: - unused func
async function getGoogleCalendarList(): Promise<any> {
  const resp = await fetch(`/api/users/events/google/calendars`);

  const respJson = await resp.json();

  handleHttpError(resp, respJson);

  return respJson.data;
}

export function initFilterDropdown() {
  const targetEl = document.getElementById("dropdown-calendar-filter");
  const triggerEl = document.getElementById("calendar-filter-button");

  const options: DropdownOptions = {
    placement: "bottom",
    triggerType: "click",
    offsetSkidding: 0,
    offsetDistance: 10,
  };

  const instanceOptions: InstanceOptions = {
    id: "dropdownMenu",
    override: true,
  };

  new Dropdown(targetEl, triggerEl, options, instanceOptions);
}

import hotkeys from "hotkeys-js";
import {
  addNewTaskModal,
  editTaskModal,
  focusModal,
  openNewTaskModal,
  settingsModal,
  todayModal,
} from "./components/flowbite";
import { RightSidebar } from "./components/right-sidebar";
import { getAlpineDataByElement } from "./store";
import { onFocusModal } from "./components/focus";

export const initHotkeys = (
  rightSidebarMain: RightSidebar,
  topSearchBarInput: HTMLInputElement
) => {
  let mouseX = 0;
  let mouseY = 0;

  // Update mouse position on mousemove
  document.addEventListener("mousemove", (event) => {
    mouseX = event.clientX;
    mouseY = event.clientY;
  });

  // @ts-ignore
  hotkeys("a", (event: any, handler: any) => {
    if (addNewTaskModal.isVisible()) {
      return;
    }

    const rightSidebarTabs = FlowbiteInstances.getInstance(
      "Tabs",
      "main-sidebar"
    );

    if (
      rightSidebarTabs.getActiveTab().id == "#main-sidebar-inbox" &&
      rightSidebarMain.isOpen
    ) {
      document.getElementById("add-inbox-task-button")?.click();
      return;
    }

    openNewTaskModal();
  });

  // @ts-ignore
  hotkeys(",", (event: any, handler: any) => {
    if (addNewTaskModal.isVisible()) {
      addNewTaskModal.hide();
    }

    if (!editTaskModal.isVisible()) {
      editTaskModal.hide();
    }

    if (!settingsModal.isVisible()) {
      document.getElementById("settings-menu-btn")?.click();
    } else {
      settingsModal.hide();
    }
  });

  // @ts-ignore
  hotkeys("c", (event: any, handler: any) => {
    const viewType = Alpine.store("viewType");
    if (viewType === "work") {
      document.getElementById("personal-view")?.click();
      return;
    }
    document.getElementById("work-view")?.click();
  });

  // @ts-ignore
  hotkeys("i", (event: any, handler: any) => {
    document.getElementById("main-sidebar-inbox-tab")?.click();
  });

  // @ts-ignore
  hotkeys("/", (event: any, handler: any) => {
    event.preventDefault();
    if (document.activeElement !== topSearchBarInput) {
      topSearchBarInput?.focus();
    }
  });

  // @ts-ignore
  hotkeys("t", (event: any, handler: any) => {
    todayModal.toggle();
  });

  // @ts-ignore
  hotkeys("f", (event: any, handler: any) => {
    const elementUnderMouse = document.elementFromPoint(
      mouseX,
      mouseY
    ) as HTMLElement;

    // Find the closest parent with class "task-card"
    const taskCardElement = elementUnderMouse?.closest(
      ".task-card"
    ) as HTMLElement;

    let hoveredTaskCardData = getAlpineDataByElement(taskCardElement);
    // Check if the element under the mouse is a .task-card
    if (hoveredTaskCardData) {
      onFocusModal(hoveredTaskCardData.taskID);
      window.history.pushState(
        "",
        "",
        "/t/" + hoveredTaskCardData.taskID + "/focus"
      );
      return;
    }

    if (focusModal.isVisible()) {
      focusModal.hide();
      return;
    }
    if (editTaskModal.isVisible()) {
      (<HTMLElement>document.querySelector(".open-focus-modal")).click();
    }
  });
};

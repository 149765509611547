import { Editor, Node } from "@tiptap/core";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Sortable, { SortableEvent } from "sortablejs";
import tippy, { Instance } from "tippy.js";
import { Action } from "../types/action";
import { decodeHtmlEntities, handleHttpError } from "../utils";

export let actionInputsTiptap: Editor[] = [];
let popup: Instance[];

export const initActions = (taskID: string) => {
  const actionInputs = document.querySelectorAll(`.action-input`);

  if (!actionInputs) {
    console.error("Action input not found");
    return;
  }

  actionInputsTiptap.forEach((el) => {
    el.destroy();
  });

  actionInputs.forEach((el) => {
    let actionInputTiptap = new Editor({
      element: el,
      extensions: [
        Text,
        Paragraph.extend({
          addKeyboardShortcuts: () => {
            return {
              Enter: () => {
                addAction(actionInputTiptap.getText().trim(), taskID);
                return true;
              },
            };
          },
        }),
        Node.create({
          name: "oneLiner",
          topNode: true,
          content: "block",
        }),
        Placeholder.configure({
          placeholder: ({}) => {
            return "New action item";
          },
        }),
      ],
      editorProps: {
        attributes: {
          class:
            "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-li:m-0 w-full",
        },
      },
    });
    actionInputsTiptap.push(actionInputTiptap);
  });

  document.querySelectorAll(`.task-actions`).forEach((el) => {
    Sortable.create(<HTMLElement>el, {
      group: "actions",
      animation: 100,
      forceFallback: true,
      dragClass: "drag-card",
      ghostClass: "ghost-card",
      easing: "cubic-bezier(0, 0.55, 0.45, 1)",
      onEnd: (evt: SortableEvent) => {
        sortActions(evt);
      },
    });
  });

  createActionPriorityDropdown();
};

export const sortActions = async (evt: SortableEvent) => {
  const x_dataTo = Alpine.$data(evt?.item) as any;
  const resp = await fetch("/api/users/tasks/actions/sort/" + x_dataTo.id, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(<Action>{
      order_index: evt.newIndex,
    }),
  });

  if (!resp.ok) {
    throw new Error("HTTP status " + resp.status);
  }
};

export const initEditActionName = async (
  el: HTMLInputElement,
  action: Action
) => {
  let updateTimeout: number;
  new Editor({
    element: el,
    extensions: [
      Text,
      Paragraph,
      Node.create({
        name: "oneLiner",
        topNode: true,
        content: "block",
      }),
    ],
    onUpdate: (props) => {
      clearTimeout(updateTimeout);

      updateTimeout = setTimeout(() => {
        updateAction(el, <Action>{
          id: action.id,
          name: props.editor.getText().trim(),
        });
      }, 200);
    },

    content: action.name,
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm focus:outline-none prose-p:my-0 prose-p:text-neutral-50 prose-p:text-sm prose-p:text-neutral-50 w-full ",
      },
    },
  });
};

export const addAction = async (name: string, taskID: string) => {
  if (name === "") {
    return;
  }
  actionInputsTiptap.forEach((el) => {
    el.destroy();
  });
  actionInputsTiptap = [];
  const resp = await fetch("/api/users/tasks/actions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(<Action>{
      name: name,
      task_id: taskID,
    }),
  });

  initActions(taskID);
  const respJson = await resp.json();

  handleHttpError(resp, respJson);

  document.querySelectorAll(`.task-actions`).forEach((el) => {
    console.log(el);
    el.insertBefore(
      decodeHtmlEntities(respJson.action),
      el.querySelector(".action-input")
    );
  });

  document.querySelectorAll(`.actions-progress`).forEach((el) => {
    el.replaceWith(decodeHtmlEntities(respJson.progress));
  });

  actionInputsTiptap.forEach((el) => {
    el.commands.clearContent();
    el.commands.focus();
  });
};

export const updateAction = async (
  btn: HTMLInputElement,
  action: Action,
  taskID?: string
) => {
  btn.disabled = true;
  const resp = await fetch("/api/users/tasks/actions/" + action.id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(action),
  });

  const respJson = await resp.json();
  btn.disabled = false;

  handleHttpError(resp, respJson);

  if (action.name !== undefined) {
    return;
  }

  if (action.priority !== undefined) {
    popup[0].hide();
  }
  document.querySelectorAll(".actions-container").forEach((el) => {
    console.log(el, respJson.actions);
    el.replaceWith(decodeHtmlEntities(respJson.action));
  });

  initActions(<string>taskID);
};

export const deleteAction = async (btn: HTMLInputElement, actionID: string) => {
  btn.disabled = true;
  const resp = await fetch("/api/users/tasks/actions/" + actionID, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const respJson = await resp.json();

  btn.disabled = false;
  handleHttpError(resp, respJson);

  document.querySelectorAll(`.task-action-${actionID}`).forEach((el) => {
    el.remove();
  });

  document.querySelectorAll(`.actions-progress`).forEach((el) => {
    el.replaceWith(decodeHtmlEntities(respJson.progress));
  });
};

export const createActionPriorityDropdown = () => {
  const dropdownPrioritDiv = document.createElement("div");
  dropdownPrioritDiv.innerHTML = JSON.parse(
    <string>(
      (<HTMLFormElement>document.querySelector("#priorities-dropdown"))
        .textContent
    )
  );
  popup = tippy("body", {
    appendTo: () => document.body,
    content: <HTMLElement>(
      dropdownPrioritDiv.querySelector("#action-priority-menu")
    ),
    showOnCreate: false,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
  });
};

export const initActionPriorityDropdown = (btn: HTMLElement) => {
  const clickedElementRect = btn.getBoundingClientRect();
  popup[0].setProps({
    getReferenceClientRect: () => clickedElementRect,
  });

  popup[0].show();
};

export const clearActionsSession = async () => {
  const resp = await fetch("/api/users/tasks/actions/session", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const respJson = await resp.json();

  handleHttpError(resp, respJson);

  document
    .querySelector(".actions-container")
    ?.replaceWith(decodeHtmlEntities(respJson.actions));
};

// export const getActions = async (taskID: string) => {
//   const resp = await fetch("/api/users/tasks/actions/" + taskID, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });

//   const respJson = await resp.json();

//   handleHttpError(resp, respJson);

//   return respJson;
// }

// Define the interface for the Alpine component's context
interface SearchDropdownContext {
  selectedIndex: number;
  selectNext: () => void;
  selectPrev: () => void;
  highlightResult: () => void;
  selectResult: () => void;
  $refs: {
    results: HTMLDivElement | null;
  };
}

const searchDropdown = (): SearchDropdownContext => {
  return {
    selectedIndex: -1,

    selectNext: function () {
      const results = this.$refs.results?.children;
      if (results && this.selectedIndex < results.length - 1) {
        this.selectedIndex++;
      }
      this.highlightResult();
    },

    selectPrev: function () {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
      this.highlightResult();
    },

    highlightResult: function () {
      const results = this.$refs.results?.children;
      if (results) {
        Array.from(results).forEach((result, index) => {
          if (index === this.selectedIndex) {
            result.classList.add("bg-neutral-100", "dark:bg-neutral-600");
          } else {
            result.classList.remove("bg-neutral-100", "dark:bg-neutral-600");
          }
        });
      }
    },

    selectResult: function () {
      const results = this.$refs.results?.children;
      if (results) {
        Array.from(results).forEach((result, index) => {
          if (index === this.selectedIndex) {
            (<HTMLElement>result.querySelector("a")).click();
          }
        });
      }
    },

    $refs: {
      results: null, // Initialize $refs as null
    },
  };
};

(window as any).searchDropdown = searchDropdown;
